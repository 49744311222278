
.busines-card {
  margin-top: 0.875rem;
  margin-right: 0.4375rem;
  box-shadow: none;
}

.business-card-body-with-header {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 6.875rem;
}

.business-card-body {
  padding-top: 0;
  padding-bottom: 0;
  //we need this margin because of the avatar
  // margin-top: 110px;

  &:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
  }
}

.business-card-image-size {
  max-width: 12.5rem;
}

.business-card-image {
  max-width: 12.5rem;
  margin-top: 4.7rem;
  margin-bottom: -6rem;
  border-color: $white-color;
  border-style: solid;
  border-width: 5px;
}

.new-line {
  white-space: pre-wrap;
}

.business-card-header {
  // background-color: $info-color;
  background-image: linear-gradient($info-color, $light-blue);

  &:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  }
}
.business-card-name {
  text-transform: capitalize;
  font-weight: $font-weight-bold;
  // padding-top: 30px;
  font-size: 1.5rem;
}

.business-card-title {
  text-transform: capitalize;
  font-weight: $font-weight-bold;
  margin-top: -0.625rem;
}

.business-card-footer {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding: 0.75rem 1.25rem;
  margin-top: 0;

  // enable this if you want to add a top line
  // border-top: 1px solid rgba(0,0,0,.125);
}

// .business-card-footer{
//     border-top: thin;
//     border-top-style: groove;
//     // border-style: groove;
//     border-color: red($color: #000000);
// }
.spoken-languages{
  margin-top: -0.625rem;
  font-size: 1.2em;
}

.language-pill{
  background-color: $dark-gray;
  // margin-top: -0.625rem;
}

.availability{
  // margin-top: -0.625rem;
  font-size: 1.2em;
}