
.service-preview-card{
    box-shadow: none;
}

.service-preview-card-body-title{
    margin-top: 0rem;
}

.service-preview-card-col{
    margin-bottom: 1rem;
}

@keyframes fa-blink {
    0% { opacity: 1; }
    50% { opacity: 0.7; }
    100% { opacity: 1; }
}
.fa-blink {
  -webkit-animation: fa-blink .75s linear infinite;
  -moz-animation: fa-blink .75s linear infinite;
  -ms-animation: fa-blink .75s linear infinite;
  -o-animation: fa-blink .75s linear infinite;
  animation: fa-blink 3.5s linear infinite;
}

.maps-icon {
    padding: 13px 3px;
    color: $icon-background-color;
}

.capitalize{
    text-transform: capitalize;
}