.news-content{
    margin: 1.1rem;
    margin-bottom: 1.0rem;
}

.image-details{
    width: 19.5625rem;
    height: 175px;
}

.media-title{
    margin-top: 0rem;
}
.preview-content{
    margin-bottom: 0.4rem;
}

.read-more{
    text-align: right;
    color: $info-color;
}

.article{
    // margin-top: 3rem;
    padding: 1rem;
    border-style: dashed;
}

.news-ribbon{
    min-height: 0rem !important;
    // color: $orange-bg;
}