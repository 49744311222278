.button-container {
  text-align: center;
  margin-top: -90px;
  font-size: 1.2em;
}
//this is for the dots in the carousel
.custom-dot-list-style {
  .react-multi-carousel-dot button {
    width: 30px;
    height: 30px;
  }
}

//these are for the switch
.bootstrap-switch {
  width: 65px !important;
  height: 26px;
  margin-bottom: 0px;
}

.bootstrap-switch-large {
  margin-top: -1px;
  margin-left: -1px;
  height: 27px !important;
  width: 100%;

  .bootstrap-switch-label {
    margin-left: -12px;
    height: 1.6875rem !important;
  }

  .bootstrap-switch-handle-off {
    font-size: small !important;
    line-height: 1.33 !important;
    width: 52% !important;
  }
  .bootstrap-switch-handle-on {
    font-size: small !important;
    line-height: 1.33 !important;
    margin-left: -3px;
  }
}

.bootstrap-switch-off {
  background: $default-color;
}
.bootstrap-switch-on {
  background: $info-color;
}

/*
For device sizes
https://stackoverflow.com/questions/41574776/what-is-class-mb-0-in-bootstrap-4

*/

.content-center-other-pages {
  position: absolute;
  top: 58%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #ffffff;
  padding: 0 15px;
  width: 100%;
  max-width: 880px;
}

@media screen and (min-width: 280px) {
  h1 {
    font-size: 2.2em;
  }
  .button-online-appointments {
    margin-top: -11.5rem;
    text-align: center;
  }
}

/* Small devices (landscape phones, 320px and up) */
@media screen and (min-width: 320px) {
  h1 {
    font-size: 2.3em;
  }
  .button-online-appointments {
    margin-top: -10.5rem;
    text-align: center;
  }
  .title-landing-page {
    font-weight: $font-weight-bold;
    padding-top: 30px;
  }
  .page-header {
    &.page-header-xsmall {
      min-height: 6vh;
    }
  }
  .title-small-header {
    font-weight: $font-weight-light;
    font-size: $font-size-h4;
    padding-top: 5.1rem;
  }

  .our-services-go-back-button{
    margin-top: -0.2rem;
  }
  .opening-hours {
    font-weight: bold;
    font-size: medium;
  }
  .ad-card-title{
    font-size: 1.2rem;
    // color: #e95e38;
    // color: #ff9172;
    // color: #ff9679;
    // color: #ff9248;
    color: #ffa579;
  }
  .telehealth-header{
    font-size: medium;
    margin-top: -1.25rem;
    color: #ffffff;
  }
  .telehealth-footer{
    margin-top: 16.1rem !important;
  }
  .telehealth-shield{
    margin-bottom: 25px;
  }
}

/* Small devices (landscape phones, 320px and up) */
@media (min-width: 320px) and (orientation: landscape) {
  .opening-hours {
    font-weight: bold;
    font-size: large;
  }

}

/* Small devices (landscape phones, 360px and up) */
@media screen and (min-width: 360px) {
  h1 {
    font-size: 2.6em;
  }
  .button-online-appointments {
    margin-top: -11.5rem;
    text-align: center;
  }
  .title-small-header {
    padding-top: 5rem;
  }
  .our-services-go-back-button{
    margin-top: -0.5rem;
  }
  .opening-hours {
    font-weight: bold;
    font-size: large;
  }
  .ad-card-title{
    font-size: 1.5rem;
  }
  .telehealth-header{
    font-size: large;
    margin-top: -1.4rem;
  }

  .telehealth-footer{
    margin-top: 16.6rem !important;
  }
}

/* Small devices (landscape phones, 411px and up) */
@media screen and (min-width: 411px) {
  h1 {
    font-size: 2.6em;
  }
  .button-online-appointments {
    margin-top: -13rem;
    text-align: center;
  }
  .title-small-header {
    font-size: $font-size-h3;
    padding-top: 4.9rem;
  }
  .our-services-go-back-button{
    margin-top: -0.6rem;
  }
  .ad-card-title{
    font-size: 1.8rem;
  }
  .telehealth-footer{
    margin-top: 14.3rem !important;
  }
  .telehealth-header{
    margin-top: -1.6rem;
    color: #ffffff;
  }
  .telehealth-footer{
    margin-top: 16.4rem !important;
  }
}

@media screen and (min-width: 411px) and (orientation: landscape) {
  h1 {
    margin-top: 1rem;
    font-size: 2em;
  }
  .button-online-appointments {
    margin-top: -8.5rem;
    text-align: center;
  }
  .page-header {
    &.page-header-xsmall {
      min-height: 10vh;
    }
  }
  
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (min-width: 576px) {
  h1 {
    font-size: 3.2em;
  }
  .telehealth-footer{
    margin-top: 15rem !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (min-width: 576px) and (orientation: landscape) {
  h1 {
    font-size: 2.2em;
  }
  .button-online-appointments {
    margin-top: -9rem;
    text-align: center;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (min-width: 640px) and (orientation: landscape) {
  h1 {
    font-size: 2.2em;
  }
  .button-online-appointments {
    margin-top: -9rem;
    text-align: center;
  }
}

/* Medium devices (tablets, 768px and up) */
@media screen and (min-width: 768px) {
  h1 {
    font-size: 3.5em;
  }
  .button-online-appointments {
    margin-top: -15.5rem;
    text-align: center;
  }
  .page-header {
    &.page-header-xsmall {
      min-height: 17vh;
      max-height: 640px;
    }
  }
  .title-small-header {
    font-weight: $font-weight-bold;
    font-size: $font-size-h2;
    padding-top: 70px;
  }
  .our-services-go-back-button{
    margin-top: -2.6rem;
  }
  .ad-card-title{
    font-size: 1.6rem;
  }
  .telehealth-header{
    margin-top: -1.3rem;
    color: #ffffff;
  }
  .telehealth-footer{
    margin-top: 16.5rem !important;
  }
}

@media screen and (min-width: 768px) and (orientation: landscape) {
  h1 {
    font-size: 2.2em;
  }
  .button-online-appointments {
    margin-top: -9.2rem;
    text-align: center;
  }
}

/* Large devices (desktops, 992px and up) */
@media screen and (min-width: 992px) {
  h1 {
    font-size: 58px;
  }
  .button-online-appointments {
    margin-top: -18rem;
    text-align: center;
  }
  .title-small-header {
    font-size: $font-size-h1;
    padding-top: 80px;
  }
  .ad-card-title{
    font-size: 2.0rem;
  }
  .telehealth-header{
    margin-top: -1.7rem;
    color: #ffffff;
  }
  .telehealth-footer{
    margin-top: 16.1rem !important;
  }
  .telehealth-shield{
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 992px) and (orientation: landscape) {
  h1 {
    font-size: 3em;
  }
  .button-online-appointments {
    margin-top: -12rem;
    text-align: center;
  }
  .page-header {
    &.page-header-xsmall {
      min-height: 20vh;
      max-height: 640px;
    }
  }
  .title-small-header {
    font-size: $font-size-h1;
    padding-top: 80px;
  }

}

/* Extra large devices (large desktops, 1200px and up) */
@media screen and (min-width: 1200px) {
  h1 {
    font-size: 3.5em;
  }
  .button-online-appointments {
    margin-top: -14rem;
    text-align: center;
  }
  .page-header {
    &.page-header-xsmall {
      min-height: 17vh;
      max-height: 640px;
    }
  }
  .title-small-header {
    padding-top: 60px;
  }
  .bulk-billed-image {
    margin-top: -1.6rem;
  }

  .ad-card-title{
    font-size: 2.0rem;
  }

}


// .career-card-image {
//   // max-width: 12.5rem;
//   max-width: 11.5rem;
//   // margin-top: 4.7rem;
//   margin-bottom: 0rem;
//   // border-color: $white-color;
//   border-color: $white-color;
//   border-style: solid;
//   // border-width: 5px;
// }