
@media (min-width: 320px) {
  .not-found-title {
    margin-top: 2rem;
    font-size: 2em;
    text-transform: uppercase;
    font-weight: 300;
  }
  .go-back-home-button {
    margin-top: -2rem;
  }
}

/* Small devices (landscape phones, 360px and up) */
@media (min-width: 360px) {
  .go-back-home-button {
    margin-top: -1.5rem;
  }
}

/* Small devices (landscape phones, 411px and up) */
@media (min-width: 411px) {
  .not-found-title {
    margin-top: 3.5rem;
    font-size: 2em;
    text-transform: uppercase;
    font-weight: 300;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .not-found-title {
    margin-top: 6rem;
    font-size: 2.5em;
    text-transform: uppercase;
    font-weight: 300;
  }
  .go-back-home-button {
    margin-top: 0rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .not-found-title {
    margin-top: 10rem;
    font-size: 3em;
    text-transform: uppercase;
    font-weight: 300;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .not-found-title {
    margin-top: 5rem;
    font-size: 3em;
    text-transform: uppercase;
    font-weight: 300;
  }
  .go-back-home-button {
    margin-top: -1rem;
  }
}
