// .fees-table-row {
//   font-size: 1.3em;
//   font-weight: 300;
// }
.fees-table-row {
  tr {
    
      font-size: 1.3em;
      font-weight: 300;
    
  }
}
