$card-smoke-bg: #f2f2f2;
$card-height-1x: 6.25rem;
$card-height-2x: 12.5rem;
$card-height-3x: 18.75rem;
$card-height-4x: 25rem;
$card-height-5x: 31.25rem;

.fa {
  color: $primary-color;
  // background: radial-gradient(grey 50%, transparent 50%);
}
.fa-icon-card-header{
  color: $icon-background-color;
  margin-right : .8rem;
}


.card-deck-data {
  margin-top: 25px;
  margin-bottom: 25px;
}

.card-data {
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.35);
  .card-header {
    font-size: xx-large;
    // font-size: 2.5em;
    color: $white-color;
    font-weight: 700;
    padding: 16px;
    margin: 0;
    background-color: $blue-color;

    &:first-child {
      border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
    }
  }

  .card-body {
    background-color: $card-smoke-bg;
    &:last-child {
      border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
    }

    .card-title-icon-text {
      display: flex;
      padding-left: 10px;
      margin-bottom: 0;

      //   .icon {
      //     padding-left: 1.25rem;
      //   }

      .text {
        font-size: 1.6em;
        font-weight: 400;
        line-height: 2;
        padding-left: 10px;
      }
    }

    .card-title-image {
      img {
        height: auto;
        width: 60%;
        /* even more control with max-width */
        // max-width: 180px;
      }
    }

    .card-title-image-text {
      display: flex;
      padding-left: 10px;
      margin-bottom: 0;

      img {
        height: auto;
        width: 40%;
      }

      .text {
        font-size: 1.6em;
        font-weight: 400;
        line-height: 2;
        padding-left: 10px;
      }
    }

    .card-title-text {
      font-size: 1.6em;
      // font-weight: 400;
      font-weight: bold;
      line-height: 2;
      padding-left: 10px;
    }

    .card-content {
      &.height-size-1x {
        height: $card-height-1x;
      }
      &.height-size-2x {
        height: $card-height-2x;
      }
      &.height-size-3x {
        height: $card-height-3x;
      }

      .text-justified {
        text-align: justify;
        // text-justify: inter-word;
      }

      .center-in {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .center-in-fixed-height-1x {
        height: $card-height-1x;
        @extend .center-in;
      }

      .center-in-fixed-height-2x {
        height: $card-height-2x;
        @extend .center-in;
      }

      .center-in-fixed-height-3x {
        height: $card-height-3x;
        @extend .center-in;
      }

      .content-text {
        // font-size: 1.4em;
        // font-size: $font-paragraph;
        // font-weight: bold;
        .overflow-comp {
          overflow-y: scroll;
        }

        a,
        p {
          font-weight: bold;
          //   font-size: $font-paragraph;
          font-size: $font-size-card-content;
        }

        div {
          p {
            font-weight: bold;
            font-size: $font-paragraph;
          }
        }
      }

      .content-button {
        font-size: $font-size-card-content;
      }

      .align-left {
        float: left;
      }
      .align-right {
        float: right;
      }
      .clear-both {
        clear: both;
      }
    }
  }

  .card-footer-padding {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding: 0.75rem 1.25rem;
    margin-top: 0;
    // enable this if you want to add a top line
    border-top: 1px solid rgba(0,0,0,.125);
  }

  // .card-footer-padding

  // .card-footer {
  //   margin-top: 0;
  //   padding: 0.75rem 1.25rem;
  //   border-top: 1px solid rgba(0, 0, 0, 0.125);
  //   // background-color: $blue-color;
  //   color: $white-color;
  // }
}

.card-content-title {
  font-weight: bold;
  font-size: large;
  
}



// for News

.scrollbar-ripe-malinka::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.scrollbar-ripe-malinka::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.scrollbar-ripe-malinka::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #e6b396 0%, $primary-color 100%);
  background-image: linear-gradient(120deg, #e6b396 0%, $primary-color 100%);
}

// .example-1 {
//   position: relative;
//   overflow-y: scroll;
//   height: 200px;
// }

.overflow-vertical {
  overflow-y: scroll; 
  height: 300px; 
  position: relative;
}

.font-paragraph {
  font-size: $font-paragraph;
}
//style={{ overflowY: "auto", height: "300px", position: "relative" }}

.phone-badge{
  font-size: 1.3em;
}

.phone-badge-title{
  @extend .phone-badge;
  margin-top: -5.3rem;
  margin-bottom: 3.6rem;
  margin-right: 0.4rem;
}
